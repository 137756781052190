@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);

@font-face {
	font-family: 'S-CoreDream-4Regular';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-4Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

* {
	box-sizing: border-box;
	font-family: 'NanumSquare', sans-serif;
}

.ant-select-open .ant-select-selection,
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
	box-shadow: none !important;
}

/*jellybook UI Components*/
.jellybook-toast {
	position: absolute;
	width: 100%;
	top: 102px;
	left: 0;
	display: flex;
	flex-flow: column wrap;
	align-content: center;
}

.jellybook-toast__item {
	text-align: center;
	padding: 0 30px;
	color: #fff;
	height: 56px;
	line-height: 56px;
	border-radius: 56px;
	background-color: rgba(127, 86, 195, 0.5);
	transform: skew(0.05deg);

	& + div {
		margin-top: 10px;
	}
}

.jellybook-dimmed {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(24, 21, 45, 0.3);
	z-index: 30;
	justify-content: center;
	align-items: center;
	display: flex;
	visibility: hidden;

	&.on {
		visibility: visible;
	}
}

.ant-modal-mask,
.ant-modal {
	transition: none;
}

.jellybook-dialog {
	transform: skew(0.05deg);
	background-color: #fff;
	border-radius: 8px;
	position: relative;
	overflow: hidden;
	width: 330px;
	animation-duration: 0s !important;

	&--info,
	&--confirm {
		border-radius: 8px;
		position: relative;
		overflow: hidden;
		background-color: transparent;
		width: 330px !important;
		transition: none !important;

		i {
			display: none;
		}

		.ant-modal-content {
			background-color: transparent;
		}

		.ant-modal-wrap,
		.ant-modal,
		.ant-modal-mask {
			transition: none;
		}

		.ant-modal-body {
			padding: 0;
			transition: none !important;
			@extend .jellybook-dialog;
			background-color: transparent !important;
		}

		.ant-modal-confirm-body {
			background-color: transparent !important;
		}

		.ant-modal-confirm-body-wrapper {
			transition: none !important;
		}

		.ant-modal-confirm-title {
			@extend .jellybook-dialog__header;
			color: #fff;
		}

		.ant-modal-confirm-content {
			@extend .jellybook-dialog__contents;
			font-size: 12px;
			font-weight: bold;
			margin: 0 !important;
			background-color: #fff;
		}

		.ant-modal-confirm-btns {
			float: none;
			margin-top: 0;
			background-color: #fff;
			@extend .jellybook-dialog__btn-wrap;

			.ant-btn {
				@extend .jellybook-button;

				&-primary {
					box-shadow: 0 2px 6px 0 rgba(28, 21, 56, 0.24);
					background-color: #ed7c89 !important;
					color: #fff !important;
				}
			}
		}
	}
}

.jellybook-dialog__header {
	height: 52px;
	line-height: 52px;
	padding-left: 20px;
	padding-right: 24px;
	background-color: #ed7c89;
}

.jellybook-dialog__title {
	font-size: 16px;
	color: #fff;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.jellybook-dialog__close-btn {
	width: 24px;
	height: 24px;
	outline: 0;
	background-image: url(../assets/images/ic-popover-close.svg);
	background-size: 12px;
	background-position: 0 6px;
	background-repeat: no-repeat;
	position: absolute;
	right: 0;
	top: 6px;
	cursor: pointer;
}

.jellybook-dialog__contents {
	max-height: 455px;
	padding: 20px;
	overflow-y: auto;
}

.jellybook-dialog__btn-wrap {
	padding: 20px;
	display: flex;
	justify-content: flex-end;
}

.jellybook-button {
	height: 37px;
	font-size: 14px;
	font-weight: bold;
	transform: skew(0.05deg);
	border-radius: 3px;
	min-width: 76px;
	cursor: pointer;
	background-color: #fff;
	border: solid 1px #eadfff;
	color: #e55863;

	&.type-jellybook {
		box-shadow: 0 2px 6px 0 rgba(28, 21, 56, 0.24);
		background-color: #e55863;
		color: #fff;
	}

	& + button {
		margin-left: 5px;
	}
}

/* Ant design Custom */
.jellybook-alert {
	width: 270px !important;
	border-radius: 8px;
	top: 50%;
	transform: translateY(-50%);
	animation-duration: 0s !important;

	.ant-modal-content {
		border-radius: 8px;
	}

	.ant-modal-body {
		padding: 0;
	}

	.ant-modal-confirm-title {
		display: none;
	}

	i {
		display: none;
	}

	.ant-modal-confirm-content {
		margin: 0 !important;
		font-size: 14px;
		font-weight: bold;
		padding: 20px 34px;
		transform: skew(0.05deg);
		color: #151515;
		text-align: center;
		white-space: pre-wrap;
	}

	.ant-modal-confirm-btns {
		float: none;
		margin: 0;
		display: flex;
		border-top: 1px solid #eee;
		overflow: hidden;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.ant-btn {
		height: 40px;
		font-weight: 400;
		transform: skew(0.05deg);
		flex: 1;
		border: none;
		color: #151515;
		border-radius: 0;
		background-color: #fff;

		+ .ant-btn {
			border-left: 1px solid #eee;
			margin: 0;
		}

		&-primary {
			font-weight: 900;
		}
	}
}

.ant-message {
	&.jellybook-toast {
		top: 100px;

		* {
			animation-duration: 0.4s !important;
		}

		.ant-message-notice-content {
			padding: 0 30px;
			height: 56px;
			line-height: 56px;
			border-radius: 56px;
			color: #fff;
			background-color: rgba(222, 44, 64, 0.6);
			transform: skew(0.05deg);

			i {
				display: none;
			}
		}
	}
}

.ant-pagination {
	float: none;
	display: flex;
	justify-content: center;
}

.ant-table-thead > tr > th {
	background-color: inherit;
	font-size: 12px;
	color: #c2c2c2;
	font-weight: bold;
	border: none;
	transform: skew(0.05deg);
}

.ant-table-tbody > tr > td {
	font-size: 14px;
	color: #151515;
	transform: skew(0.05deg);
	border: none;
	font-weight: 600;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
	width: 21px;
	height: 21px;

	font-size: 12px;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
	min-width: 21px;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
	color: #d9d9d9;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
	color: #e55863 !important;
	border-color: #e55863;
}

.anticon {
	vertical-align: 3px;
}

.ant-pagination-item {
	height: 21px;
	min-width: 21px;
	line-height: 23px;
	border-radius: 0;
	border-color: #eee;

	&:hover {
		border-color: #e55863;
	}
}

.ant-pagination-item a {
	padding: 0 5px;
	height: 100%;
	color: #d5d5d5;
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev {
	height: 21px;
	min-width: 21px;
	line-height: 23px;
	border-radius: 0;
	border: 1px solid #eeeeee;

	&:hover {
		border: 1px solid #e55863;
	}
}

.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
	&:hover {
		.ant-pagination-item-link-icon {
			color: #e55863 !important;
		}
	}
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
	color: rgba(0, 0, 0, 0.65);

	&:hover {
		&.ant-pagination-item-link-icon {
			color: #e55863;
		}
	}
}

.ant-pagination-item-ellipsis {
	opacity: 1;
}

:root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
:root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
	&:hover {
		color: #e55863;
	}
}

.ant-pagination-item-active {
	border-color: #e55863;
	color: #e55863;
}

.ant-pagination-item-active a {
	border-color: #e55863;
	color: #e55863;
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
	border-color: #e55863;
	color: #e55863;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
	border-radius: 0;
}

.ant-pagination-item-active:hover,
.ant-pagination-item-active:focus {
	border-color: #e55863;
}

.ant-radio-inner {
	width: 10px;
	height: 10px;
	border: none;
}

.ant-radio-wrapper {
	display: inline-flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
}

.ant-radio-inner {
	background-color: #e0e0e0;
}

.ant-radio-checked:after {
	border: 1px solid #e55863;
}

.ant-radio-checked .ant-radio-inner {
	border-color: #e55863;
}

.ant-radio-checked .ant-radio-inner::after {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #e55863;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
	border-color: #e55863;
}

span.ant-radio + * {
	padding-right: 7px;
	padding-left: 7px;
	font-size: 14px;
	color: #151515;
	transform: skew(0.05deg);
}

.ant-table-tbody > tr {
	padding-left: 3px;

	&:hover {
		box-shadow: 0 0 10px 0 rgba(33, 25, 67, 0.13);

		> td:nth-child(1):before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 3px;
			height: 100%;
			background-color: #e55863;
		}
	}
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
	background-color: #fff;
}

.ant-pagination {
	&.jellybook {
		padding: 20px 0;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		border-top: 1px solid #eee;
	}
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
	height: 21px;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
	opacity: 1;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
	color: #d5d5d5;
}

.ant-pagination-jump-prev {
	&:hover {
		.ant-pagination-item-link-icon {
			color: #e55863;
		}
	}
}

.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
	line-height: 30px;
}
